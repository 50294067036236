import React from 'react';
import PropTypes from 'prop-types';

import { Link, useLocation, useHistory } from 'react-router-dom';

import { connect } from 'react-redux';

function Footer({ i18n }) {
  const location = useLocation();
  const history = useHistory();

  const navigateToHomepage = () => {
    if (location.pathname !== '/') {
      history.push({
        pathname: '/',
      });
    }

    setTimeout(() => {
      const el = document.getElementById(history.location.hash.split('#')[1]);
      el.scrollIntoView();
    }, 100);
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-3 col-lg-4">
            <h4>{i18n.t('footer.companyName')}</h4>
            {/* <p className="small-text">© {i18n.t('footer.copyright_text')}</p> */}
            <p className="medium-text">
              {i18n.t('footer.address_line_1')}
              <br />
              {i18n.t('footer.address_line_2')}
            </p>
            <div className="contact-links">
              <ul className="list-unstyled">
                <li>
                  <i className="fas fa-phone"></i>{' '}
                  <a href={`tel://${i18n.t('footer.company.realPhone')}`}>
                    {i18n.t('footer.company.displayPhone')}
                  </a>
                  <br />
                </li>
                <li>
                  <i className="far fa-envelope"></i>{' '}
                  <a href={`mailto:${i18n.t('footer.company.email')}`}>
                    {i18n.t('footer.company.email')}
                  </a>
                  <br />
                </li>
                <li>
                  <i className="fab fa-linkedin"></i>{' '}
                  <a href="https://www.linkedin.com/company/rixt-recruitment">
                    {i18n.t('footer.company.linkedin')}
                  </a>
                  <br />
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-5 col-lg-5">
            <p className="medium-text">
              {i18n.t('footer.office_title')}
              <br />
              {i18n.t('footer.office_para_1')}
              <br />
              {i18n.t('footer.office_para_2')}
            </p>
          </div>
          <div className="col-md-4 col-lg-3">
            <ul className="quick-links">
              <li>
                <Link to="/vacatures">
                  {i18n.t('homepage.topnav.vacancies')}
                </Link>
              </li>
              <li>
                <a
                  className="footer-link"
                  onClick={() => navigateToHomepage()}
                  href="#about-section"
                >
                  {i18n.t('homepage.topnav.about_us')}
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  onClick={() => navigateToHomepage()}
                  href="#services-section"
                >
                  {i18n.t('homepage.topnav.services')}
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  onClick={() => navigateToHomepage()}
                  href="#ambitions-section"
                >
                  {i18n.t('homepage.topnav.employers')}
                </a>
              </li>
              <li>
                <a
                  className="footer-link"
                  onClick={() => navigateToHomepage()}
                  href="#benefits-section"
                >
                  {i18n.t('homepage.topnav.candidates')}
                </a>
              </li>
              <li>
                <Link to="/contact">{i18n.t('homepage.topnav.contact')}</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row row--links">
          <div className="col">
            <Link className="link--small" to="/terms-and-conditions">
              {i18n.t('footer.terms_and_conditions')}
            </Link>
            <Link className="link--small" to="/privacy">
              {i18n.t('footer.privacy_policy')}
            </Link>
            <Link className="link--small" to="/cookie-policy">
              {i18n.t('footer.cookie_policy')}
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  i18n: PropTypes.object.isRequired,
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
