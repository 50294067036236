import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

function Filters({ i18n, searchJobs, areFiltersCleared }) {
  const language = i18n.currentLanguage();

  const employmentTypeOptions = [
    {
      value: 'Vast',
      label: language.toLowerCase() === 'nl' ? 'Vast' : 'Permanent'
    },
    {
      value: 'Interim',
      label: 'Interim'
    }
  ];

  const industryTypeOptions = [
    {
      value: 'IT',
      label: 'IT'
    },
    {
      value: 'Digital_Technology',
      label: 'Digital Technology'
    },
    {
      value: 'IT_Security',
      label: 'IT Security'
    },
    {
      value: 'IT_Risk_Management',
      label: 'IT Risk Management'
    },
    {
      value: 'Finance',
      label: 'Finance'
    },
    {
      value: 'RIXT',
      label: 'RIXT'
    },
  ];

  const [keyword, setKeyword] = useState('');
  const [location, setLocation] = useState('');
  const [radius, setRadius] = useState('');
  const [employmentType, setEmploymentType] = useState([]);
  const [industryType, setIndustryType] = useState([]);
  const [minSalary, setMinSalary] = useState('');
  const [maxSalary, setMaxSalary] = useState('');
  const [optionsMessage, setOptionsMessage] = useState(i18n.t('jobspage.filters.location_filter.type_message'));
  const history = useHistory();

  const prepareData = () => {
    const data = {
      title: keyword,
      location: location.value !== undefined ? location.value.terms[0].value : '',
      radius,
      minSalary,
      maxSalary,
      employmentType: employmentType.value !== undefined ? employmentType.value : '',
      industryType: industryType.value !== undefined ? industryType.value : ''
    };

    searchJobs(data);

    history.push({
      pathname: '/vacatures',
      state: {}
    });
  };

  const resetData = () => {
    setKeyword('');
    setLocation('');
    setRadius('');
    setEmploymentType([]);
    setIndustryType([]);
    setMinSalary('');
    setMaxSalary('');
  };

  useEffect(() => {
    if (areFiltersCleared === true) {
      resetData();
    }
  }, [areFiltersCleared]);

  const handleKeywordInput = (e) => {
    if (e.which === 13) {
      prepareData();
    }
  };

  useEffect(() => {
    prepareData();
  }, [location]);

  return (
    <div className="filters-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-2">
            <div className="keyword-filter">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z" /></svg>
              <input type="text" placeholder={i18n.t('jobspage.filters.keyword_filter.placeholder')} value={keyword} onKeyDown={e => handleKeywordInput(e)} onChange={(e) => setKeyword(e.target.value)} />
            </div>
          </div>
          <div className="col-lg-2">
            <div className="location-filter">
              <GooglePlacesAutocomplete
                apiKey={window.__ENV__.GMAP_API_KEY}
                apiOptions={{ language }}
                autocompletionRequest={{
                  componentRestrictions: {
                    country: ['nl'],
                  },
                  types: ['(cities)']
                }}
                selectProps={{
                  value: location,
                  onChange: setLocation,
                  classNamePrefix: 'select-dropdown',
                  placeholder: i18n.t('jobspage.filters.location_filter.placeholder'),
                  className: 'select-dropdown-container',
                  onInputChange(newValue, { action: inputChange }) {
                    if (newValue.length > 0) {
                      setOptionsMessage(i18n.t('jobspage.filters.location_filter.no_cities_message'));
                    } else {
                      setOptionsMessage(i18n.t('jobspage.filters.location_filter.type_message'));
                    }
                  },
                  noOptionsMessage: () => optionsMessage
                }} />
            </div>
          </div>
          <div className="col-lg-2">
            <div className="employment-filter">
              <Select classNamePrefix="select-dropdown" className="select-dropdown-container" options={employmentTypeOptions} value={employmentType} onChange={(option) => setEmploymentType(option)} placeholder={i18n.t('jobspage.filters.employment_filter.placeholder')}></Select>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="industry-filter">
              <Select classNamePrefix="select-dropdown" className="select-dropdown-container" options={industryTypeOptions} value={industryType} onChange={(option) => setIndustryType(option)} placeholder={i18n.t('jobspage.filters.industry_filter.placeholder')}></Select>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="salary-range-filter">
              <span>{i18n.t('jobspage.filters.salary_range_filter.placeholder')}</span>
              <div className="salary-range-input-container salary-range-min">
                <span>&euro;</span>
                <input type="number" min="0" placeholder="0" value={minSalary} onChange={e => setMinSalary(e.target.value)} />
              </div>
              <span className="range-separator">-</span>
              <div className="salary-range-input-container salary-range-max">
                <span>&euro;</span>
                <input type="number" min="0" placeholder="0" value={maxSalary} onChange={e => setMaxSalary(e.target.value)} />
              </div>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <button type="button" className="btn btn-primary mx-auto" onClick={prepareData}>
            <span>{i18n.t('jobspage.filters.search_button')}</span>
          </button>
        </div>
      </div>
    </div>
  );
}

Filters.propTypes = {
  i18n: PropTypes.object.isRequired,
  searchJobs: PropTypes.func.isRequired,
  areFiltersCleared: PropTypes.bool.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Filters);
