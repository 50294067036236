import React from 'react';
import PropTypes from 'prop-types';

const LogoCard = ({ img }) => (
  <div className="logo__card">
    <img className="logo-image" src={`https://content.erip.me/api/assets/rixt-dev/${img}`} alt="Logo" />
  </div>
);

LogoCard.propTypes = {
  img: PropTypes.string.isRequired
};

export default LogoCard;
