import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import sliderLeftArrow from 'assets/slider-left-arrow.svg';
import sliderRightArrow from 'assets/slider-right-arrow.svg';
import slash from 'assets/slash.svg';

const CustomArrows = ({ next, previous }) => (
  <div className="arrow-buttons">
    <img onClick={() => previous()} src={sliderLeftArrow} alt="left arrow" />
    <img src={slash} alt="partition between left and right arrow buttons" />
    <img onClick={() => next()} src={sliderRightArrow} alt="right arrow" />
  </div>
);

CustomArrows.propTypes = {
  next: PropTypes.func.isRequired,
  previous: PropTypes.func.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CustomArrows);
