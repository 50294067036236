import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import _ from 'lodash';

import { connect } from 'react-redux';

import VacancyList from './VacancyList';

function AllVacanciesView({ i18n, vacancies, perPageOptions, perPageValue, setPerPageOption, sortOption, setSortOption, sortOptions, totalPages, next, prev, navigateToPage, currentPage, showClearButton, resetFilters, totalVacancies }) {

  const isActivePageClass = (pageNumber) => {
    if (pageNumber === currentPage) {
      return 'active';
    }

    return '';
  };

  const renderPageButtons = () => _.range(1, totalPages + 1).map(page => (<button key={page} className={`btn-page ${isActivePageClass(page)}`} type="button" onClick={() => navigateToPage(page)}>{page}</button>));

  const renderStartRecordNumber = () => ((currentPage - 1) * perPageValue.value) + 1;

  const renderEndRecordNumber = () => {
    if (((currentPage - 1) * perPageValue.value) + perPageValue.value <= totalVacancies) {
      return ((currentPage - 1) * perPageValue.value) + perPageValue.value;
    }

    return ((currentPage - 1) * perPageValue.value) + vacancies.length;
  };

  return (
    <div className="vacancy-list-wrapper">
      {vacancies.length
        ? (
          <div className="container">
            <div className="row">
              <div className="col">
                <h1>{i18n.t('jobspage.jobs_section.title')}.</h1>
                <div className="row sort-row">
                  <div className="dropdown-container">
                    <span>{i18n.t('jobspage.jobs_section.sort_filter.label')}:</span>
                    <Select value={sortOption} options={sortOptions} className="select-dropdown-container" classNamePrefix="select-dropdown" onChange={(option) => setSortOption(option)} />
                  </div>
                  {
                    !showClearButton ? (<button className="btn btn-secondary btn-clear" type="button" onClick={() => resetFilters()}>{i18n.t('button.clear_filters')}</button>) : ''
                  }
                </div>
              </div>
            </div>
            <VacancyList vacancies={vacancies} />
            <div className="vacancy-list-footer row">
              <div className="col-md-4 per-page-container">
                <div className="dropdown-container">
                  <span className="label">{i18n.t('jobspage.jobs_section.per_page_text')}</span>
                  <Select options={perPageOptions} value={perPageValue} className="select-dropdown-container" classNamePrefix="select-dropdown" onChange={option => setPerPageOption(option)} />
                </div>
              </div>
              <div className="col-md-4 pagination-wrapper">
                <button className="btn-prev" type="button" aria-label="Previous page button" disabled={currentPage === 1} onClick={prev}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z" /></svg>
                </button>
                {
             renderPageButtons()
           }
                <button className="btn-next" type="button" aria-label="Next page button" disabled={currentPage === totalPages} onClick={next}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z" /></svg>
                </button>
              </div>
              <div className="col-md-4 info-wrapper">
                <span>{renderStartRecordNumber()} - {renderEndRecordNumber()} {i18n.t('jobspage.jobs_section.of_text')} {totalVacancies}</span>
              </div>
            </div>
          </div>
        )
        : (
          <div className="container pb-5">
            <h3 className="mt-5 pt-5 mb-4">{i18n.t('jobspage.no_jobs_text')}</h3>
            <div>
              <button className="btn btn-secondary btn-clear mb-5" type="button" onClick={() => resetFilters()}>{i18n.t('button.clear_filters')}</button>
            </div>
          </div>
        )}
    </div>
  );
}

AllVacanciesView.propTypes = {
  i18n: PropTypes.object.isRequired,
  vacancies: PropTypes.array.isRequired,
  perPageOptions: PropTypes.array.isRequired,
  perPageValue: PropTypes.object.isRequired,
  setPerPageOption: PropTypes.func.isRequired,
  sortOption: PropTypes.object.isRequired,
  setSortOption: PropTypes.func.isRequired,
  sortOptions: PropTypes.array.isRequired,
  totalPages: PropTypes.number.isRequired,
  next: PropTypes.func.isRequired,
  prev: PropTypes.func.isRequired,
  navigateToPage: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  showClearButton: PropTypes.bool.isRequired,
  resetFilters: PropTypes.func.isRequired,
  totalVacancies: PropTypes.number.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(AllVacanciesView);
