import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { appSettings } from 'settings';
import { request } from '@hydra/atom/lib';
import { toast } from 'react-toastify';

const FormData = require('form-data');

function FileUploader({ setResume, buttonText, placeholder }) {

  const errorNotification = (msg) => toast.error(msg);

  const hiddenFileInput = useRef(null);
  const [filename, setFilename] = useState(placeholder);

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append('file', file);

    request({
      url: `${appSettings.apiBaseUrl}uploads/file`,
      method: 'POST',
      data: formData
    })
      .then((res) => setResume(`${appSettings.apiBaseUrl}uploads/file/${res.fileReferenceNumber}`))
      .catch(err => errorNotification(err));
  };

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  const handleChange = event => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.name !== undefined) {
      uploadFile(fileUploaded);
      setFilename(fileUploaded.name);
    }
  };

  return (
    <div className="file-uploader-wrapper">
      <div className="file-uploader">
        <span>{filename}</span>
        <button type="button" onClick={handleClick}>{buttonText}</button>
      </div>
      <input
        type="file"
        accept=".doc,.docx,.pdf,application/msword"
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </div>
  );
}

FileUploader.propTypes = {
  setResume: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};

export default FileUploader;
