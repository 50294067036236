import React from 'react';
import PropTypes from 'prop-types';

const TestimonialCard = ({
  img,
  subtitle,
  heading,
  description,
  personName,
}) => (
  <div className="row testimonial__card">
    <div className="image-container">
      <img
        className="backdrop-image"
        src={`https://content.erip.me/api/assets/rixt-dev/${img}`}
        alt={`${personName} face`}
      />
    </div>
    <div className="col-lg-4 offset-lg-1">
      <img
        className="testimonial-image"
        src={`https://content.erip.me/api/assets/rixt-dev/${img}`}
        alt={`${personName} face`}
      />
    </div>
    <div className="col-lg-6 offset-lg-1">
      <p className="subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></p>
      <h3>{heading}</h3>
      <p className="large-text">{description}</p>
      <p className="medium-text">- {personName}</p>
    </div>
  </div>
);

TestimonialCard.propTypes = {
  img: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  personName: PropTypes.string.isRequired,
};

export default TestimonialCard;
