import React from 'react';

import Hero from 'components/Homepage/Hero';
import Benefits from 'components/Homepage/Benefits';
import Services from 'components/Homepage/Services';
import Markets from 'components/Homepage/Markets';
import Testimonial from 'components/common/testimonials/Testimonial';
import About from 'components/Homepage/About';

const Homepage = () => (
  <div className="page home-page">
    <Hero />
    <Benefits />
    <Services />
    <Markets />
    <Testimonial />
    <About />
  </div>
);

export default Homepage;
