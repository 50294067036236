/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import TopNav from 'components/TopNav';
import Footer from 'components/Footer';

const withHomePageLayout = (WrappedComponent) => (props) => (
  <div className="homepage-wrapper">
    <TopNav />
    <WrappedComponent {...props} />
    <Footer />
  </div>
);

export default withHomePageLayout;
