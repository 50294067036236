/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import SecondaryNav from 'components/Vacanciespage/SecondaryNav';
import Footer from 'components/Footer';

const withVacancyPageLayout = (WrappedComponent) => (props) => (
  <div className="vacancypage-wrapper">
    <SecondaryNav />
    <WrappedComponent {...props} />
    <Footer />
  </div>
);

export default withVacancyPageLayout;
