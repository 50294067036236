import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { getTestimonials } from 'api';

import { connect } from 'react-redux';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import TestimonialCard from './TestimonialCard';

const responsive = {
  xl: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  lg: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  md: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  sm: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Testimonials = ({ i18n }) => {
  const language = i18n.currentLanguage();

  const [testimonialItems, setTestimonialItems] = useState([]);

  useEffect(() => {
    getTestimonials(language.toLowerCase())
      .then(resp => {
        const data = resp.items;
        const teamTestimonials = data.filter(item => item.data.Client === false);
        setTestimonialItems(teamTestimonials);
      })
      .catch(err => console.log(err));

    return () => {};
  }, []);

  return (
    <Carousel
      responsive={responsive}
      draggable
      infinite
      swipeable
      dotListClass="custom-dot"
      renderDotsOutside
      showDots
      removeArrowOnDeviceType={['xl', 'lg', 'md', 'sm']}
      itemClass="master-slider"
    >
      {testimonialItems.map(({ data }, index) => (
        <TestimonialCard
          description={data.Body}
          personName={data.Name}
          footerText={data.Position}
          img={data.Avatar[0]}
          key={index}
      />
      ))}
    </Carousel>
  );

};

Testimonials.propTypes = {
  i18n: PropTypes.object.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);
