import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import ContactModal from 'components/common/ContactModal';
import ScrollDownImg from 'assets/scroll-down.svg';

const Hero = ({ i18n }) => (
  <header className="homepage-hero">
    <div className="container">
      <div className="row">
        <div className="col col-lg-10 offset-lg-1">
          <h1>{i18n.t('homepage.hero.title')}</h1>
          <p className="lead-text">
            {i18n.t('homepage.hero.para')}
          </p>
          <Link className="btn btn-primary" to="/contact">{i18n.t('homepage.hero.button_text')}</Link>
        </div>
      </div>
      <a href="#benefits-section"><img className="scroll-down-image" src={ScrollDownImg} alt="Graphic to hint the user to scroll down" /></a>
    </div>
  </header>
);

Hero.propTypes = {
  i18n: PropTypes.object.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Hero);
