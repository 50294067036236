const baseUrl = window.__ENV__.BASE_URL;
const sentryUrl = window.__ENV__.SENTRY_URL;
const cmsUrl = window.__ENV__.CMS_URL;

export const appSettings = {
  baseUrl,
  sentryUrl,
  cmsBaseUrl: `${cmsUrl}/api/v1`,
  apiBaseUrl: `${baseUrl}`,
  authCookieName: '.rixtLoggedIn',
  languageCookieName: '.rixtLanguage'
};
