import React from 'react';

import SingleVacancyView from 'components/Vacanciespage/SingleVacancyView';

const Vacancies = () => (
  <div className="page vacancy-page">
    <SingleVacancyView />
  </div>
);

export default Vacancies;
