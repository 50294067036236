/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';
import { formatPrice } from 'utils/formatPrice';

function VacancyList({ i18n, vacancies }) {

  const language = i18n.currentLanguage();

  const history = useHistory();

  const navigateToVacanciesPage = (slug) => {
    history.push(`/vacature/${slug}`);
  };

  const renderSalaryType = (type) => type[language.toLowerCase()];

  return (
    <ul className="row vacancy-list">
      {vacancies.map((vacancy, index) => (
        <li key={index} className="col-md-6 col-lg-4">
          <div className="vacancy-wrapper" onClick={() => navigateToVacanciesPage(vacancy.slug[`${language.toLowerCase()}`])}>
            <header>
              {
                vacancy.avatar !== undefined && vacancy.avatar.iv !== undefined
                  ? (
                    <div
                      className="vacancy-image-container"
                      style={{
                        backgroundImage:
                      `url(https://content.erip.me/api/assets/rixt-dev/${vacancy.avatar.iv})`
                      }}
                    ></div>
                  )
                  : (<></>)
              }
              <div className="header-content">
                <p className="subtitle">{vacancy.location}</p>
                {
                  vacancy !== undefined && vacancy[`title${language.toUpperCase()}`]
                    ? <h4>{vacancy[`title${language.toUpperCase()}`]}</h4>
                    : <></>
                }
                {
                  vacancy.subtitle !== undefined
                    ? <p className="description">{vacancy.subtitle[`${language.toLowerCase()}`]}</p>
                    : <></>
                }
              </div>
            </header>
            <div className="footer">
              <p>{formatPrice(vacancy.minSalary)} - {formatPrice(vacancy.maxSalary)} {renderSalaryType(vacancy.salaryType)}</p>
              <Link to={`/vacature/${vacancy.slug[`${language.toLowerCase()}`]}`}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M166.9 264.5l-117.8 116c-4.7 4.7-12.3 4.7-17 0l-7.1-7.1c-4.7-4.7-4.7-12.3 0-17L127.3 256 25.1 155.6c-4.7-4.7-4.7-12.3 0-17l7.1-7.1c4.7-4.7 12.3-4.7 17 0l117.8 116c4.6 4.7 4.6 12.3-.1 17z" /></svg>
              </Link>
            </div>
          </div>
        </li>
      ))}
    </ul>
  );
}

VacancyList.propTypes = {
  i18n: PropTypes.object.isRequired,
  vacancies: PropTypes.array.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(VacancyList);
