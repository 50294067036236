import { request } from '@hydra/atom/lib';
import { appSettings } from '../settings';

export const getJobById = (jobId, lang) => request({
  url: `${appSettings.apiBaseUrl}api/job/${jobId}?lang=${lang}`,
  method: 'GET'
});

export const getJobBySlug = (slug, lang) => request({
  url: `${appSettings.apiBaseUrl}api/job?slug=${slug}&lang=${lang}`,
  method: 'GET'
});

export const getJobs = (filters) => request({
  url: `${appSettings.apiBaseUrl}api/jobs`,
  method: 'GET',
  params: {
    ...filters
  }
});

export const getAllJobs = (query) => request({
  url: `${appSettings.apiBaseUrl}api/v2/jobs?${query}`,
  method: 'GET'
});

export const contactForJob = (data) => request({
  url: `${appSettings.apiBaseUrl}api/v1/job/contact`,
  method: 'POST',
  headers: {
    'Content-Type': 'application/json'
  },
  data
});

export const getTestimonials = (lang) => request({
  url: `${appSettings.apiBaseUrl}api/testimonials?lang=${lang}`,
  method: 'GET'
});

export const getRecruiterById = (id) => request({
  url: `${appSettings.apiBaseUrl}api/recruiter/${id}`,
  method: 'GET'
});

export const getLogos = () => request({
  url: `${appSettings.apiBaseUrl}api/logos`,
  method: 'GET'
});
