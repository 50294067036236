import React from 'react';
import PropTypes from 'prop-types';

const TestimonialCard = ({ img, description, personName, footerText }) => (
  <div className="testimonial__card">
    <div className="testimonial__card-image-container">
      <div
        className="testimonial-image"
        style={{
          backgroundImage:
                  `url(https://content.erip.me/api/assets/rixt-dev/${img})`
        }} />
    </div>
    <div className="testimonial__card-body">
      <p className="testimonial__card-testimonial-text medium-text">
        {description}
      </p>
      <p className="testimonial__card-person-name medium-text">- {personName}</p>
      <p className="testimonial__card-footer-text small-text">{footerText}</p>
    </div>
  </div>
);

TestimonialCard.propTypes = {
  img: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  personName: PropTypes.string.isRequired,
  footerText: PropTypes.string.isRequired,
};

export default TestimonialCard;
