import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fetchGET } from '@hydra/atom/lib';
import { appSettings } from 'settings';

import { connect } from 'react-redux';

import Hero from 'components/Contentpage/Hero';
import Content from 'components/Contentpage/Content';

const Privacy = ({ i18n }) => {

  const locale = i18n.currentLanguage().toLowerCase();
  const [content, setContent] = useState({});

  useEffect(() => {
    fetchGET(`${appSettings.cmsBaseUrl}/content/privacy-policy?locale=${locale}`, {
      headers: {
        'App-name': 'rixt'
      },
      onSuccess: (data) => {
        setContent(data);
      },
      onError: (error) => {
        console.log(error);
      }
    });
  }, []);

  return (
    <div className="page content-page">
      <Hero headingText={content.title} />
      <Content content={content.body} />
    </div>
  );
};

Privacy.propTypes = {
  i18n: PropTypes.object.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);
