import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { AddressPinMap } from '@hydra/atom/components/common/maps';

function Map({ i18n }) {
  const popupContent = () => (
    <div className="row p-3">
      <div className="col">
        <p className="display-1">
          <strong>De Constant Rebecquestraat 50</strong>
        </p>

        <p className="display-1">
          2518 RE Den Haag<br />
          Netherlands
        </p>
      </div>
    </div>
  );

  return (
    <AddressPinMap lng={4.293374962805821} lat={52.07671811983083} popupContent={popupContent()} />
  );
}

Map.propTypes = {
  i18n: PropTypes.object.isRequired,
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Map);
