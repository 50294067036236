import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  EmailIcon
} from 'react-share';

function SocialShareModal({ i18n, vacancy }) {

  const [open, setOpen] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const location = useLocation();

  const shareUrl = `https://www.rixt-recruitment.nl/${location.pathname}`;

  return (
    <div className="d-inline-block">
      <button onClick={onOpenModal} type="button" className="btn-share">
        <span className="material-icons">
          share
        </span>
      </button>
      <Modal modalId='social-share-modal' open={open} onClose={onCloseModal} center>
        <h3 className="text-center">{i18n.t('share_modal.share_text')}:</h3>
        <div className="sharing-btns-wrapper d-flex justify-content-center mt-3">
          <FacebookShareButton className="mr-4" url={shareUrl} quote={vacancy.Title}><FacebookIcon size={48} round /></FacebookShareButton>
          <LinkedinShareButton className="mr-4" url={shareUrl} title={vacancy.Title} summary={vacancy.Subtitle} source="https://www.rixt-recruitment.nl"><LinkedinIcon size={48} round /> </LinkedinShareButton>
          <TwitterShareButton className="mr-4" url={shareUrl} title={vacancy.Title}><TwitterIcon size={48} round /></TwitterShareButton>
          <EmailShareButton url={shareUrl} subject={vacancy.Title}><EmailIcon size={48} round /></EmailShareButton>
        </div>
      </Modal>
    </div>
  );
}

SocialShareModal.propTypes = {
  i18n: PropTypes.object.isRequired,
  vacancy: PropTypes.object.isRequired,
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SocialShareModal);
