import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import Testimonial from './testimonials/Testimonial';
import Logos from './logo-carousel/Logos';

function Employers({ i18n }) {
  return (
    <section id="employers-section">
      <div className="background-image"></div>
      <div className="container">
        <div className="row content-row">
          <div className="testimonials-wrapper col-sm-8 col-md-6 col-lg-4 offset-lg-1">
            <Testimonial />
          </div>
          <div className="content-wrapper col-md-12 col-lg-6 offset-lg-1">
            <h2>
              {i18n.t('jobspage.testimonials_section.title')}
            </h2>
            <div className="logos-wrapper">
              <Logos />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

Employers.propTypes = {
  i18n: PropTypes.object.isRequired,
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Employers);
