import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import visual1 from 'assets/random-v.jpg';
import visual2 from 'assets/random-h-2.jpg';
import visual3 from 'assets/random-h-3.jpg';
import visual4 from 'assets/random-h.jpg';
import visual5 from 'assets/random-h-4.jpg';
import visual6 from 'assets/random-v-2.jpg';

import ContactModal from 'components/common/ContactModal';
import Visuals from './Visuals';

const Benefits = ({ i18n }) => (
  <section id="benefits-section">
    <div className="curled-bg-wrapper">
      <div className="container">
        <div className="row header-row">
          <div className="col col-lg-10 offset-lg-1">
            <p className="subtitle">{i18n.t('homepage.recruitment_solutions_section.subtitle')}</p>
            <h2>{i18n.t('homepage.recruitment_solutions_section.title')}</h2>
            <p className="lead-text">
              {i18n.t('homepage.recruitment_solutions_section.para')}
            </p>
            <div className="cta-wrapper">
              <Link className="btn btn-primary" to="/contact">{i18n.t('homepage.recruitment_solutions_section.button_text')}</Link>
              <a href="tel:000-123-4567">{i18n.t('homepage.recruitment_solutions_section.link_text')}</a>
            </div>
          </div>
        </div>
      </div>
      <Visuals visual1={visual1} visual2={visual2} visual3={visual3} />
    </div>
    <div className="container" id="ambitions-section">
      <div className="row header-row">
        <div className="col col-lg-10 offset-lg-1">
          <p className="subtitle">{i18n.t('homepage.recruitment_expertise_section.subtitle')}</p>
          <h2>{i18n.t('homepage.recruitment_expertise_section.title')}</h2>
          <p className="lead-text">
            {i18n.t('homepage.recruitment_expertise_section.para')}
          </p>
          <div className="cta-wrapper">
            <ContactModal buttonText={i18n.t('homepage.recruitment_expertise_section.button_text')} />
            <a href="tel:000-123-4567">{i18n.t('homepage.recruitment_expertise_section.link_text')}</a>
          </div>
        </div>
      </div>
    </div>
    <div className="curled-bg-wrapper">
      <Visuals alignment="right" visual1={visual4} visual2={visual5} visual3={visual6} />
      <div className="container">
        <div className="row header-row">
          <div className="col col-lg-10 offset-lg-1">
            <p className="subtitle">{i18n.t('homepage.quick_fixes_section.subtitle')}</p>
            <h2>{i18n.t('homepage.quick_fixes_section.title')}</h2>
            <p className="lead-text">
              {i18n.t('homepage.quick_fixes_section.para')}
            </p>
            <div className="cta-wrapper">
              <ContactModal buttonText={i18n.t('homepage.quick_fixes_section.button_text')} />
              <a href="tel:000-123-4567">{i18n.t('homepage.quick_fixes_section.link_text')}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);

Benefits.propTypes = {
  i18n: PropTypes.object.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Benefits);
