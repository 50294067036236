import React from 'react';

import ContactWrapper from 'components/Contactpage/ContactWrapper';

const Contact = () => (
  <div className="page contact-page">
    <ContactWrapper />
  </div>
);

export default Contact;
