import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ProtectedRoute } from '@hydra/atom/components';

// Import Home Page
import Homepage from 'pages/Homepage/Homepage';

// Import Content Pages
import Privacy from 'pages/Contentpage/Privacy';
import CookiePolicy from 'pages/Contentpage/CookiePolicy';
import TermsAndConditions from 'pages/Contentpage/TermsAndConditions';

// Import Vacancy Pages
import Vacancies from 'pages/Vacanciespage/Vacancies';
import SingleVacancypage from 'pages/SingleVacancypage/SingleVacancypage';
import Contact from 'pages/Contactpage/Contact';

// Import layouts
import withContentPageLayout from 'pages/layouts/withContentPageLayout';
import withHomePageLayout from 'pages/layouts/withHomePageLayout';
import withVacancyPageLayout from 'pages/layouts/withVacancyPageLayout';

import { appSettings } from 'settings';

const { authCookieName } = appSettings;

export const buildRoutes = (isAuthenticated) => (
  <Switch>
    <Route path="/" component={withHomePageLayout(Homepage)} exact />
    <Route path="/privacy" component={withContentPageLayout(Privacy)} />
    <Route path="/terms-and-conditions" component={withContentPageLayout(TermsAndConditions)} />
    <Route path="/cookie-policy" component={withContentPageLayout(CookiePolicy)} />
    <Route path="/vacatures" component={withVacancyPageLayout(Vacancies)} />
    <Route path="/vacature/:slug" component={withVacancyPageLayout(SingleVacancypage)} />
    <Route path="/contact" component={withVacancyPageLayout(Contact)} />

    {/* <Route component={NotFound} /> */}
  </Switch>
);
