import React from 'react';
import PropTypes from 'prop-types';

function Visuals({ visual1, visual2, visual3, alignment }) {
  return (
    <div className={`visuals container-fluid ${alignment}`}>
      <div className="wrapper container">
        <div className="left">
          <img src={visual1} alt="" />
        </div>
        <div className="right">
          <img src={visual2} alt="" />
          <img src={visual3} alt="" />
        </div>
      </div>
    </div>
  );
}

Visuals.propTypes = {
  visual1: PropTypes.string.isRequired,
  visual2: PropTypes.string.isRequired,
  visual3: PropTypes.string.isRequired,
  alignment: PropTypes.string
};

Visuals.defaultProps = {
  alignment: ''
};

export default Visuals;
