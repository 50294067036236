import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { updateLanguage } from '@hydra/atom/lib/actions';

import NLflag from 'assets/flag-nl.svg';
import UKflag from 'assets/flag-uk.svg';

class Language extends Component {
  static propTypes = {
    i18n: PropTypes.object.isRequired,

    updateLanguage: PropTypes.func.isRequired
  }

  get activeFlag() {
    const { i18n } = this.props;
    const language = i18n.currentLanguage();

    if (language === 'NL') {
      return (
        <img src={NLflag} className="mr-1" alt="nl" />
      );
    }

    return (
      <img src={UKflag} className="mr-1" alt="uk" />
    );
  }

  render() {
    const { i18n } = this.props;
    const language = i18n.currentLanguage();

    return (
      <div className="dropdown select-language">
        <a className="dropdown-toggle" id="languageDropdown" role="button" tabIndex={0} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <span>{(language !== undefined) ? i18n.t(`shared.${language}_CODE`) : 'Updating'}</span> {this.activeFlag}
        </a>
        <div className="dropdown-menu" aria-labelledby="languageDropdown">
          <a
            role="button"
            tabIndex={0}
            className="dropdown-item px-3"
            onClick={() => {
              this.props.updateLanguage('NL');
              window.location.reload();
            }}>
            <img className="w-4" src={NLflag} alt="nl" />
            <span className="ml-2">{i18n.t('shared.dutch')}</span>
          </a>

          <div className="dropdown-divider" />

          <a
            role="button"
            tabIndex={0}
            className="dropdown-item px-3"
            onClick={() => { this.props.updateLanguage('EN');
              window.location.reload(); }}>
            <img className="w-4" src={UKflag} alt="uk" />
            <span className="ml-2">{i18n.t('shared.english')}</span>
          </a>
        </div>
      </div>
    );
  }
}

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
  updateLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(Language);
