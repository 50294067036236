import React from 'react';
import PropTypes from 'prop-types';

const Hero = ({ headingText }) => (
  <header>
    <div className="container">
      <div className="row">
        <div className="col">
          <h1>{headingText}</h1>
        </div>
      </div>
    </div>
  </header>
);

Hero.propTypes = {
  headingText: PropTypes.string.isRequired
};

export default Hero;
