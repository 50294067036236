import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import service1 from 'assets/service-1.jpg';
import service2 from 'assets/service-2.jpg';
import service3 from 'assets/service-3.jpg';

const Services = ({ i18n }) => (
  <section id="services-section">
    <div className="container">
      <div className="row header-row">
        <div className="col">
          <p className="subtitle">{i18n.t('homepage.our_services_section.subtitle')}</p>
          <h2>{i18n.t('homepage.our_services_section.title')}</h2>
          <p className="lead-text">
            {i18n.t('homepage.our_services_section.para')}
          </p>
        </div>
      </div>
      <div className="row services-row">
        <div className="col-md-6 col-lg-4">
          <img src={service1} alt="Executive facing the camera" />
          <h3>{i18n.t('homepage.our_services_section.service_1')}</h3>
        </div>
        <div className="col-md-6 col-lg-4">
          <img src={service2} alt="Executive on a phone" />
          <h3>{i18n.t('homepage.our_services_section.service_2')}</h3>
        </div>
        <div className="col-md-6 col-lg-4">
          <img src={service3} alt="Executive fixing the tie" />
          <h3>{i18n.t('homepage.our_services_section.service_3')}</h3>
        </div>
      </div>
      <div className="cta-wrapper">
        <Link className="btn btn-primary" to="/contact">{i18n.t('homepage.our_services_section.button_text')}</Link>
        <a href="tel:000-123-4567">{i18n.t('homepage.our_services_section.link_text')}</a>
      </div>
    </div>
  </section>
);

Services.propTypes = {
  i18n: PropTypes.object.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Services);
