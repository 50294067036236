import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Link, useParams, useHistory } from 'react-router-dom';
import { differenceInDays, differenceInHours } from 'date-fns';
import { getJobBySlug, getRecruiterById } from 'api';
import SocialShareModal from 'components/common/SocialShareModal';
import { Loader } from '@hydra/atom/components';

import { connect } from 'react-redux';
import ContactModal from 'components/common/ContactModal';
import { parseTextForTime } from 'utils/parseText';

function SingleVacancyView({ i18n }) {

  useEffect(() => {
    parseTextForTime('<<hours>> hours ago', 2);
  }, []);

  const language = i18n.currentLanguage();

  const [isLoading, setIsLoading] = useState(true);
  const [vacancy, setVacancy] = useState([]);
  const [recruiter, setRecruiter] = useState({});

  const history = useHistory();
  const { slug } = useParams();

  useEffect(() => {
    getJobBySlug(slug, language.toLowerCase())
      .then(job => {
        setVacancy(job);
        if (job.data.Recruiter !== undefined) {
          getRecruiterById(`${job.data.Recruiter[0]}`)
            .then(({ data }) => {
              // eslint-disable-next-line prefer-destructuring
              data.img = data.Avatar[0];
              setRecruiter({ ...data });
              setIsLoading(false);
            })
            .catch(err => console.log(err));
        }
        setIsLoading(false);
      })
      .catch(error => console.log(error));

    return () => {};
  }, []);

  const renderTimeDifference = (date) => {
    const dayDiff = differenceInDays(new Date(date), new Date()) * -1;

    if (dayDiff === 0) {
      const hoursDiff = differenceInHours(new Date(date), new Date()) * -1;

      switch (hoursDiff) {
        case 1:
          return parseTextForTime(i18n.t('common.text.postedHourAgo'), hoursDiff);
        default:
          return parseTextForTime(i18n.t('common.text.postedHoursAgo'), hoursDiff);
      }
    }

    switch (dayDiff) {
      case 1:
        return parseTextForTime(i18n.t('common.text.postedDayAgo'), dayDiff);
      default:
        return parseTextForTime(i18n.t('common.text.postedDaysAgo'), dayDiff);
    }
  };

  return (
    <div className="vacancy-view-wrapper">
      {
        isLoading
          ? (<Loader />)
          : (
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h1>
                    {vacancy.data.Title}
                    <br />
                    <span>{vacancy.data.Location}</span>
                  </h1>
                </div>
              </div>
              <div className="row back-row">
                <div className="col-6 col-lg-5 offset-lg-1">
                  <div className="back-button-container">
                    <Link className="button-link" to="/vacatures">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path d="M25.1 247.5l117.8-116c4.7-4.7 12.3-4.7 17 0l7.1 7.1c4.7 4.7 4.7 12.3 0 17L64.7 256l102.2 100.4c4.7 4.7 4.7 12.3 0 17l-7.1 7.1c-4.7 4.7-12.3 4.7-17 0L25 264.5c-4.6-4.7-4.6-12.3.1-17z" /></svg>
                      <span>{i18n.t('single_vacancy_page.back_button')}</span>
                    </Link>
                  </div>
                </div>
                <div className="col-6 col-lg-5 post-time">
                  <span>{renderTimeDifference(vacancy.lastModified)}</span>
                </div>
              </div>
              <div className="content-wrapper">
                <SocialShareModal vacancy={vacancy} />
                <div className="row">
                  <div className="col-md-12 col-lg-9">
                    <div className="content" dangerouslySetInnerHTML={{ __html: vacancy.data.Description }}></div>
                    <div className="cta">
                      <h4>{i18n.t('single_vacancy_page.cta_text.segment_1')} <ContactModal jobId={vacancy.data.Title} buttonText={i18n.t('single_vacancy_page.cta_button_text')} /> {i18n.t('single_vacancy_page.cta_text.segment_2')}</h4>
                    </div>
                  </div>
                  <div className="col col-sm-6 col-md-5 mt-4 mt-lg-0 mx-auto mx-lg-0 col-lg-3">
                    <div className="recruiter-card">
                      <img loading="lazy" src={`https://content.erip.me/api/assets/rixt-dev/${recruiter.img}`} alt={`${recruiter.Name}`} />
                      <h5>{recruiter.Name}</h5>
                      <div className="contact-links">
                        <ul className="list-unstyled">
                          <li><i className="fas fa-phone"></i> <a href={`tel://${recruiter.Telephone}`}>{recruiter.Telephone}</a><br /></li>
                          <li><i className="far fa-envelope"></i> <a href={`mailto:${recruiter.Email}`}>{recruiter.Email}</a><br /></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
      }
    </div>
  );
}

SingleVacancyView.propTypes = {
  i18n: PropTypes.object.isRequired,
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleVacancyView);
