import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';

import SelectLanguage from 'components/topnav/SelectLanguage';
import Logo from 'components/common/Logo';

function SecondaryNav({ i18n }) {

  const location = useLocation();

  return (
    <nav className="secondary-nav">
      <SelectLanguage />
      <div className="container">
        <div className="row">
          <div className="col-6 col-sm-6 col-md-6">
            <NavLink to="/">
              <Logo />
            </NavLink>
          </div>
          {location.pathname !== '/contact' ? (
            <div className="col-6 col-sm-6 col-md-6">
              <NavLink className="nav-link ml-auto" to="/contact">{i18n.t('jobspage.topnav.questions')}?</NavLink>
            </div>
          ) : (
            <NavLink className="nav-link btn btn__nav ml-auto" to="/vacatures">
              {i18n.t('homepage.topnav.vacancies')}
            </NavLink>
          )}
        </div>
      </div>
    </nav>
  );
}

SecondaryNav.propTypes = {
  i18n: PropTypes.object.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryNav);
