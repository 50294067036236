import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { toast } from 'react-toastify';

import { ContactForm } from '@hydra/atom/components/common/forms';
import Map from './Map';

import { appSettings } from '../../settings';

function ContactWrapper({ i18n }) {
  const formUrl = `${appSettings.apiBaseUrl}api/v1/contactus`;

  return (
    <div className="container space contact">
      <div className="row">
        <header className="col text-center">
          <h1>{i18n.t('contact.title')}</h1>
          <p className="lead">{i18n.t('contact.subtitle')}</p>
        </header>
      </div>

      <div className="row pt-md-5 form-container">
        <div className="col-12 col-md-6 map-wrapper">
          <Map />
        </div>

        <div className="col-12 col-md-6">
          <ContactForm
            i18n={i18n}
            postUrl={formUrl}
            onSubmitSuccess={() => toast.success(i18n.t('form.success_message'))}
            onSubmitFailure={(error) => toast.error(error)}
            />
        </div>
      </div>
    </div>
  );
}

ContactWrapper.propTypes = {
  i18n: PropTypes.object.isRequired,
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactWrapper);
