import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import about from 'assets/about.jpg';
import team1 from 'assets/team-1.jpg';
import avatar from 'assets/avatar.jpg';

const About = ({ i18n }) => (
  <section id="about-section">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 header-row header-column">
          <img
            src={about}
            alt="Rixt about"
            align="left"
            className="mr-lg-5 about-img mb-5 mb-lg-0"
          />
          <p className="subtitle">
            {i18n.t('homepage.about_us_section.subtitle')}
          </p>
          <h2>{i18n.t('homepage.about_us_section.title')}</h2>
          <p className="lead-text">
            {i18n.t('homepage.about_us_section.para')}
          </p>
        </div>
      </div>
    </div>
    <div id="team-section" className="container">
      <div className="row header-row">
        <div className="col">
          <p className="subtitle">
            {i18n.t('homepage.teams_section.subtitle')}
          </p>
          <h2>{i18n.t('homepage.teams_section.title')}</h2>
        </div>
      </div>
      <div className="row team-row">
        <div className="col-md-4">
          <img src={team1} alt="Rixt founder" />
          <h3>{i18n.t('homepage.teams_section.person_1.name')}</h3>
          <h4>{i18n.t('homepage.teams_section.person_1.position')}</h4>
          <p className="medium-text">
            {i18n.t('homepage.teams_section.person_1.intro')}
          </p>
          <div className="contact-links">
            <ul className="list-unstyled">
              <li>
                <i className="fas fa-phone"></i>{' '}
                <a
                  href={`tel://${i18n.t(
                    'homepage.teams_section.person_1.realPhone'
                  )}`}
                >
                  {i18n.t('homepage.teams_section.person_1.displayPhone')}
                </a>
              </li>
              <li>
                <i className="far fa-envelope"></i>{' '}
                <a href={`mailto:${i18n.t('homepage.teams_section.person_1.email')}`}>
                  {i18n.t('homepage.teams_section.person_1.email')}
                </a>
              </li>
              <li>
                <i className="fab fa-linkedin"></i>{' '}
                <a
                  href="http://www.linkedin.com/in/ameet-sidhoe"
                  target="_blank"
                >
                  {i18n.t('homepage.teams_section.person_1.linkedin')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-4">
          <img src={avatar} alt="Rixt team member" />
          <h3>{i18n.t('homepage.teams_section.person_2.name')}</h3>
          <h4>{i18n.t('homepage.teams_section.person_2.position')}</h4>
          <p className="medium-text">
            {i18n.t('homepage.teams_section.person_2.intro')}
          </p>
          <div className="contact-links">
            <ul className="list-unstyled">
              <li>
                <i className="fas fa-phone"></i>{' '}
                <a
                  href={`tel://${i18n.t(
                    'homepage.teams_section.person_2.realPhone'
                  )}`}
                >
                  {i18n.t('homepage.teams_section.person_2.displayPhone')}
                </a>
              </li>
              <li>
                <i className="far fa-envelope"></i>{' '}
                <a href={`mailto:${i18n.t('homepage.teams_section.person_2.email')}`}>
                  {i18n.t('homepage.teams_section.person_2.email')}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-4">
          <img src={avatar} alt="Rixt team member" />
          <h3>{i18n.t('homepage.teams_section.person_3.name')}</h3>
          <h4>{i18n.t('homepage.teams_section.person_3.position')}</h4>
          <p className="medium-text">
            {i18n.t('homepage.teams_section.person_3.intro')}
          </p>
          <div className="contact-links">
            <ul className="list-unstyled">
              <li>
                <i className="fas fa-phone"></i>{' '}
                <a
                  href={`tel://${i18n.t(
                    'homepage.teams_section.person_3.realPhone'
                  )}`}
                >
                  {i18n.t('homepage.teams_section.person_3.displayPhone')}
                </a>
              </li>
              <li>
                <i className="far fa-envelope"></i>{' '}
                <a href={`mailto:${i18n.t('homepage.teams_section.person_3.email')}`}>
                  {i18n.t('homepage.teams_section.person_3.email')}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
);

About.propTypes = {
  i18n: PropTypes.object.isRequired,
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(About);
