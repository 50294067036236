export const parseTextForTime = (text, hours) => {
  const content = text.replaceAll(/(<<|>>)/g, '%%');
  const splitContent = content.split('%%');

  splitContent.forEach((item, index) => {
    if (index % 2 !== 0) {
      splitContent[index] = hours;

    } else {
      splitContent[index] = item
    };
  });

  return splitContent.join('');
};