/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import TopNav from 'components/TopNav';
import Footer from 'components/Footer';
import Testimonial from 'components/common/testimonials/Testimonial';

const withContentPageLayout = (WrappedComponent) => (props) => (
  <div className="homepage-wrapper">
    <TopNav />
    <WrappedComponent {...props} />
    <Testimonial />
    <Footer />
  </div>
);

export default withContentPageLayout;
