import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { NavLink, useLocation, useHistory } from 'react-router-dom';

import { AppBar, Spacer } from '@hydra/atom/components';

import SelectLanguage from 'components/topnav/SelectLanguage';

import Logo from './common/Logo';

function TopNav({ i18n }) {

  const appBar = React.createRef();

  const closeNav = () => {
    appBar.current.toggleSideNav();
    navigateToHomepage();
  };

  const location = useLocation();
  const history = useHistory();

  const navigateToHomepage = () => {
    if (location.pathname !== '/') {
      history.push({
        pathname: '/'
      });
    }

    setTimeout(() => {
      const el = document.getElementById(history.location.hash.split('#')[1]);
      if (el) {
        el.scrollIntoView();
      }
    }, 50);
  };

  return (
    <nav className="homepage-nav">
      <SelectLanguage />
      <div className="container">
        <div className="row">
          <AppBar ref={appBar} transparent>
            <AppBar.Logo>
              <NavLink to="/">
                <Logo />
              </NavLink>
            </AppBar.Logo>
            <AppBar.ItemsHorizontal>
              <Spacer />
              <a className="nav-link" onClick={() => navigateToHomepage()} href="#benefits-section">
                {i18n.t('homepage.topnav.candidates')}
              </a>
              <a className="nav-link" onClick={() => navigateToHomepage()} href="#ambitions-section">
                {i18n.t('homepage.topnav.employers')}
              </a>
              <a className="nav-link" onClick={() => navigateToHomepage()} href="#services-section">
                {i18n.t('homepage.topnav.services')}
              </a>
              <a className="nav-link" onClick={() => navigateToHomepage()} href="#about-section">
                {i18n.t('homepage.topnav.about_us')}
              </a>
              <NavLink className="nav-link" to="/contact">
                {i18n.t('homepage.topnav.contact')}
              </NavLink>
              <NavLink className="nav-link btn btn__nav mr-3" to="/vacatures">
                {i18n.t('homepage.topnav.vacancies')}
              </NavLink>
            </AppBar.ItemsHorizontal>

            <AppBar.ItemsVertical>
              <NavLink onClick={() => closeNav()} to="/" exact>Home</NavLink>
              <a className="nav-link" href="#benefits-section" onClick={() => closeNav()}>
                {i18n.t('homepage.topnav.candidates')}
              </a>
              <a className="nav-link" href="#ambitions-section" onClick={() => closeNav()}>
                {i18n.t('homepage.topnav.employers')}
              </a>
              <a className="nav-link" href="#services-section" onClick={() => closeNav()} to="/">{i18n.t('homepage.topnav.services')}</a>
              <a className="nav-link" href="#about-section" onClick={() => closeNav()} to="/">{i18n.t('homepage.topnav.about_us')}</a>
              <NavLink className="nav-link" to="/contact" onClick={() => closeNav()}>
                {i18n.t('homepage.topnav.contact')}
              </NavLink>
              <NavLink onClick={() => closeNav()} to="/vacatures">{i18n.t('homepage.topnav.vacancies')}</NavLink>
            </AppBar.ItemsVertical>
          </AppBar>
        </div>
      </div>
    </nav>
  );
}

TopNav.propTypes = {
  i18n: PropTypes.object.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
