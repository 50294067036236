import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import market1 from 'assets/market-1.jpg';
import market2 from 'assets/market-2.jpg';

const Markets = ({ i18n }) => (
  <section id="markets-section">
    <div className="container">
      <div className="row header-row">
        <div className="col">
          <p className="subtitle">{i18n.t('homepage.markets_section.subtitle')}</p>
          <h2>{i18n.t('homepage.markets_section.title')}</h2>
          <p className="lead-text">
            {i18n.t('homepage.markets_section.para')}
          </p>
        </div>
      </div>
      <div className="row markets-row">
        <div className="col-md-6">
          <img src={market1} alt="IT & Finance market" />
          <h3>{i18n.t('homepage.markets_section.market_1')}</h3>
        </div>
        <div className="col-md-6">
          <img src={market2} alt="Risk & Audit market" />
          <h3>{i18n.t('homepage.markets_section.market_2')}</h3>
        </div>
      </div>
      <div className="cta-wrapper">
        <Link className="btn btn-primary" to="/contact">{i18n.t('homepage.markets_section.button_text')}</Link>
        <a href="tel:000-123-4567">{i18n.t('homepage.markets_section.link_text')}</a>
      </div>
    </div>
  </section>
);

Markets.propTypes = {
  i18n: PropTypes.object.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Markets);
