import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Loader } from '@hydra/atom/components';
import { getAllJobs } from 'api';
import _ from 'lodash';

import { toast } from 'react-toastify';
import Filters from './Filters';
import AllVacanciesView from './AllVacanciesView';
import Employers from './Employers';

const perPageOptions = [
  { value: 12, label: '12' },
  { value: 16, label: '16' },
  { value: 20, label: '20' }
];

function VacancyView({ i18n }) {

  const language = i18n.currentLanguage();

  const sortOptions = () => {
    switch (language) {
      case 'NL':
        return [
          { value: 'desc', label: 'nieuwste' },
          { value: 'asc', label: 'oudste' }
        ];

      default:
        return [
          { value: 'desc', label: 'newest' },
          { value: 'asc', label: 'oldest' }
        ];
    }
  };

  const [vacancies, setVacancies] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [sortOption, setSortOption] = useState(sortOptions()[0]);
  const [perPageOption, setPerPageOption] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(2);
  const [totalVacancies, setTotalVacancies] = useState(2);
  const [isClearDisabled, setIsClearDisabled] = useState(false);

  const initialFilters = {
    top: currentPage,
    skip: perPageOption.value,
    title: '',
    location: '',
    radius: '',
    minSalary: '',
    maxSalary: '',
    lang: language.toLowerCase(),
    sortBy: sortOption.value,
    employmentType: '',
    industryType: ''
  };

  const [filters, setFilters] = useState(initialFilters);

  const countTotalPages = (total) => {
    if (total % perPageOption.value === 0) {
      return total / perPageOption.value;
    }

    return (Math.floor(total / perPageOption.value) + 1);
  };

  const searchJobs = (data) => {
    setFilters(prevState => ({
      ...prevState,
      ...data
    }));
    setIsLoading(true);
  };

  const nextPage = () => setCurrentPage(currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage - 1);

  const navigateToPage = (pageNumber) => setCurrentPage(pageNumber);

  const fetchJobs = (jobFilters) => {
    setIsLoading(true);

    if (jobFilters.minSalary > jobFilters.maxSalary) {
      if (language.toLowerCase() === 'en') {
        toast.error('The maximum salary should be larger than minimum salary');
      } else {
        toast.error('Het maximumsalaris moet hoger zijn dan het minimumsalaris');
      }
      setIsLoading(false);
      return;
    }

    let query = 'query=&filter=';

    if (jobFilters.title.length) {
      query = `query=${jobFilters.title}&filters=`;
    }

    const filledFilters = {};

    if (jobFilters.location.length) {
      filledFilters.location = jobFilters.location;
    }

    if (jobFilters.employmentType.length) {
      filledFilters.employmentType = jobFilters.employmentType;
    }

    if (jobFilters.industryType.length) {
      filledFilters.industryType = jobFilters.industryType;
    }

    if (jobFilters.minSalary) {
      filledFilters.minSalary = `${jobFilters.minSalary}`;
    }

    if (jobFilters.maxSalary) {
      filledFilters.maxSalary = `${jobFilters.maxSalary}`;
    }

    const filterKeys = Object.keys(filledFilters);

    filterKeys.forEach((key, index) => {
      if (index !== filterKeys.length - 1 && key !== 'minSalary' && key !== 'maxSalary') {
        query = query.concat(`${key}=${filledFilters[key]} AND `);
      } else if (key === 'minSalary') {
        if (filledFilters.maxSalary && filledFilters.minSalary) {
          query = query.concat(`${key}>=${filledFilters[key]} AND `);
        } else {
          query = query.concat(`${key}>=${filledFilters[key]}`);
        }
      } else if (key === 'maxSalary') {
        query = query.concat(`${key}<=${filledFilters[key]}`);
      } else {
        query = query.concat(`${key}=${filledFilters[key]}`);
      }
    });

    query = query.concat(`&page=${jobFilters.top}&recordsPerPage=${jobFilters.skip}&sortBy=${jobFilters.sortBy}`);

    getAllJobs(query).then(res => {
      setVacancies(res.data);
      setTotalPages(countTotalPages(res.total));
      setTotalVacancies(res.total);
      setIsLoading(false);
    });
  };

  const resetFilters = () => {
    setFilters(initialFilters);
    setSortOption(sortOptions()[0]);
    setIsClearDisabled(false);
  };

  useEffect(() => {
    fetchJobs(filters);
    setIsClearDisabled(_.isEqual(initialFilters, filters));
  }, [filters]);

  useEffect(() => {
    setFilters(prevState => ({
      ...prevState,
      sortBy: sortOption.value
    }));
  }, [sortOption]);

  useEffect(() => {
    setFilters(prevState => ({
      ...prevState,
      top: currentPage,
      skip: perPageOption.value
    }));
  }, [currentPage]);

  useEffect(() => {
    setCurrentPage(1);
    setFilters(prevState => ({
      ...prevState,
      top: currentPage,
      skip: perPageOption.value
    }));
  }, [perPageOption]);

  return (
    <div>
      <Filters searchJobs={searchJobs} areFiltersCleared={isClearDisabled} />
      {isLoading
        ? <Loader />
        : (
          <AllVacanciesView vacancies={vacancies} totalVacancies={totalVacancies} perPageOptions={perPageOptions} perPageValue={perPageOption} setPerPageOption={setPerPageOption} sortOption={sortOption} sortOptions={sortOptions()} setSortOption={setSortOption} currentPage={currentPage} next={nextPage} prev={prevPage} navigateToPage={navigateToPage} totalPages={totalPages} showClearButton={isClearDisabled} resetFilters={resetFilters} />
        )}
      <Employers />
    </div>
  );
}

VacancyView.propTypes = {
  i18n: PropTypes.object.isRequired,
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(VacancyView);
