import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { getTestimonials } from 'api';

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import TestimonialCard from './TestimonialCard';
import CustomArrows from './CustomArrows';

const responsive = {
  xl: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  lg: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  md: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  sm: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Testimonials = ({ i18n }) => {
  const language = i18n.currentLanguage();

  const [testimonialItems, setTestimonialItems] = useState([]);

  useEffect(() => {
    getTestimonials(language.toLowerCase())
      .then(resp => {
        const data = resp.items;
        const clientTestimonials = data.filter(item => item.data.Client === true);
        setTestimonialItems(clientTestimonials);
      })
      .catch(err => console.log(err));

    return () => {};
  }, []);

  return (
    <section className="testimonials-section">
      <div className="container">
        <Carousel
          responsive={responsive}
          draggable
          infinite
          swipeable
          renderButtonGroupOutside
          customButtonGroup={<CustomArrows />}
          removeArrowOnDeviceType={['xl', 'lg', 'md', 'sm']}
          itemClass="master-slider"
      >
          {testimonialItems.map(({ data }, index) => (
            <TestimonialCard
              subtitle={data.Subtitle}
              heading={data.Title}
              description={data.Body}
              personName={data.Name}
              img={data.Avatar[0]}
              key={index}
          />
          ))}
        </Carousel>
      </div>
    </section>
  );
};

Testimonials.propTypes = {
  i18n: PropTypes.object.isRequired
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Testimonials);
