import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { contactForJob } from 'api';
import { toast } from 'react-toastify';

import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import FileUploader from 'components/common/FileUploader';
import { Form, Input, Button } from '@hydra/atom/components';

import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';

function ContactModal({ i18n, buttonText, jobId }) {

  const [open, setOpen] = useState(false);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNo, setPhoneNo] = useState('');
  const [message, setMessage] = useState('');
  const [resume, setResume] = useState('');
  const [privacyPolicyCheck, setPrivacyPolicyCheck] = useState(false);
  const [isloading, setIsLoading] = useState(false);

  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);

  const resetAllFields = () => {
    setFullName('');
    setEmail('');
    setPhoneNo('');
    setMessage('');
    setResume('');
    setPrivacyPolicyCheck(false);
  };

  const postContactData = () => {
    setIsLoading(true);
    const data = {
      fullName,
      email,
      phoneNo,
      message,
      resume,
      privacy_check: privacyPolicyCheck
    };

    if (jobId !== undefined && jobId.length) {
      data.jobId = jobId;
    }

    if (data.privacy_check) {
      contactForJob(data)
        .then(() => {
          resetAllFields();
          toast.success(i18n.t('form.success_message'));
          setIsLoading(false);
          onCloseModal();
        })
        .catch((err) => toast.error(i18n.t('form.error_message')));
    } else {
      toast.error('Privacy policy check is required');
      setIsLoading(false);
    }
  };

  return (
    <div className="d-inline-block">
      <button onClick={onOpenModal} type="button" className="btn btn-primary">{ buttonText }</button>
      <Modal open={open} onClose={onCloseModal} center>
        <h3>{i18n.t('contact_modal.title')}</h3>
        <h6>{i18n.t('contact_modal.subtitle')}</h6>
        <Form encType="multipart/form-data" onSubmit={() => postContactData()}>
          <div className="input-container">
            <label htmlFor="name">{i18n.t('contact_modal.name.label')}</label>
            <Input
              name="name"
              id="name"
              value={fullName}
              onChange={(value) => setFullName(value)}
              rules="required"
              placeholder={i18n.t('contact_modal.name.placeholder')}
            />
          </div>
          <div className="input-container">
            <label htmlFor="email">{i18n.t('contact_modal.email.label')}</label>
            <Input
              name="email"
              id="email"
              value={email}
              onChange={(value) => setEmail(value)}
              rules="required|email"
              placeholder={i18n.t('contact_modal.email.placeholder')}
            />
          </div>
          <div className="input-container">
            <label htmlFor="name">{i18n.t('contact_modal.phone.label')}</label>
            <Input
              name="phone"
              id="phone"
              value={phoneNo}
              onChange={(value) => setPhoneNo(value)}
              rules="required|phone"
              placeholder={i18n.t('contact_modal.phone.placeholder')}
            />
          </div>
          <div className="input-container">
            <label>{i18n.t('contact_modal.upload_cv.label')}</label>
            <FileUploader setResume={setResume} buttonText={i18n.t('contact_modal.upload_cv.buttonText')} placeholder={i18n.t('contact_modal.upload_cv.placeholder')} />
          </div>
          <div className="input-container">
            <label>{i18n.t('contact_modal.description.label')}</label>
            <textarea rows="4" placeholder={i18n.t('contact_modal.description.placeholder')} value={message} onChange={e => setMessage(e.target.value)}></textarea>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="privacyCheck" defaultChecked={privacyPolicyCheck} onChange={() => setPrivacyPolicyCheck(!privacyPolicyCheck)} />
            <label className="form-check-label" htmlFor="privacyCheck">
              {i18n.t('contact_modal.privacy_policy.text.group_one')} <Link to="/terms-and-conditions">{i18n.t('contact_modal.privacy_policy.terms_and_conditions.link')}</Link> {i18n.t('contact_modal.privacy_policy.text.group_two')} <Link to="/privacy">{i18n.t('contact_modal.privacy_policy.privacy.link')}</Link> {i18n.t('contact_modal.privacy_policy.text.group_three')}
            </label>
          </div>
          <Button
            type="submit"
            className="btn btn-primary"
            loading={isloading}
          >
            {i18n.t('contact_modal.submit_button')}
          </Button>
        </Form>
      </Modal>
    </div>
  );
}

ContactModal.propTypes = {
  i18n: PropTypes.object.isRequired,
  buttonText: PropTypes.string,
  jobId: PropTypes.string
};

ContactModal.defaultProps = {
  jobId: '',
  buttonText: 'Contact'
};

// connect redux
const mapStateToProps = (state) => ({
  i18n: state.app.i18n
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ContactModal);
