import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { ScrollToTop, Loader, Overlay } from '@hydra/atom/components';

import { buildRoutes } from 'routes';

class App extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired
  }

  state = {
    loading: false
  }

  render() {
    const { isAuthenticated } = this.props;
    const { loading } = this.state;

    if (loading) {
      return <Loader />;
    }

    return [
      <ToastContainer key="toasts" autoClose={3500} hideProgressBar />,

      <BrowserRouter key="router">
        <ScrollToTop>
          <Overlay />

          {buildRoutes(isAuthenticated)}

        </ScrollToTop>
      </BrowserRouter>
    ];
  }
}

// connect redux
const mapStateToProps = (state) => ({
  user: state.app.user,
  isAuthenticated: state.app.isAuthenticated
});

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
