import React from 'react';

import VacancyView from 'components/Vacanciespage/VacancyView';

const Vacancies = () => (
  <div className="page vacancy-page">
    <VacancyView />
  </div>
);

export default Vacancies;
